import React, { useEffect, useRef } from "react";
import Rellax from "rellax";

// import Img from "gatsby-image";
// import { getFluidGatsbyImage } from "gatsby-source-sanity";
// import { sanity } from "../../client-config";
import { buildImageObj, cn } from "../lib/helpers";
import shieldBlank from "../images/shield-blank.svg";
import { useMediaQuery } from "react-responsive";
import { mediaMinSmall, mediaMaxSmall } from "../lib/mediaQueries";
import { imageUrlFor } from "../lib/image-url";

import styles from "./hero.module.css";

const Hero = (props) => {
  const { className, image, mobileImage, title, subtitle } = props;

  const isDesktop = useMediaQuery(mediaMinSmall);
  const isMobile = useMediaQuery(mediaMaxSmall);

  // const fluidSettings = getFluidGatsbyImage(
  //   image.asset._id,
  //   // {
  //   //   sizes: "(max-width: 1920px) 1920px, 100vw",
  //   // },
  //   { maxWidth: 1919 },
  //   sanity
  // );

  // const mobileFluidSettings = getFluidGatsbyImage(mobileImage.asset._id, { maxWidth: 980 }, sanity);
  const imageRef = useRef(null);

  useEffect(() => {
    const rellaxOptions = {
      center: false,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false,
    };
    const imageRellax = new Rellax(imageRef.current.firstChild, {
      ...rellaxOptions,
      speed: isMobile ? 1.6 : 2.2,
    });
    return () => {
      //reset for page changes
      if (imageRellax.destroy) {
        imageRellax.destroy();
      }
    };
  });

  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.imageWrapper} ref={imageRef}>
        {isMobile && mobileImage && (
          <img
            className={styles.mainImage}
            src={imageUrlFor(buildImageObj(mobileImage)).auto("format").url()}
            alt={mobileImage.alt}
          />
        )}
        {(isDesktop || !mobileImage) && (
          <img
            className={styles.mainImage}
            src={imageUrlFor(buildImageObj(image)).auto("format").quality(100).url()}
            alt={image.alt}
          />
        )}
      </div>
      <div className={styles.info}>
        <div className={styles.infoInner}>
          <img className={styles.shieldBlank} src={shieldBlank} alt="Shield" />
          <div className={styles.titles}>
            <h1 className={cn(styles.title)}>{title}</h1>
            <div className={styles.subtitle}>{subtitle}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
