import React from "react";
import Hero from "./hero";
import TwoColumn from "./two-column";
import TextSection from "./text-section";
import Slider from "./slider";
import ImageCard from "./image-card";
import Video from "./video";
import FullImage from "./full-image";
import LottieSection from "./lottie-section";
import styles from "./page-sections.module.css";
import { cn } from "../lib/helpers";
import RiveTier from "./rive-tier";
const PageSections = (props) => {
  const { content, isThemeDark = false, hasPaddingTop = false, className } = props;
  const sections = (content || [])
    .filter((c) => !c.disabled)
    .map((c, i) => {
      let el = null;
      switch (c._type) {
        case "hero":
          el = <Hero key={c._key} {...c} />;
          break;
        case "twoColumn":
          el = <TwoColumn key={c._key} {...c} />;
          break;
        case "textSection":
          el = <TextSection key={c._key} {...c} />;
          break;
        case "video":
          el = <Video key={c._key} {...c} />;
          break;
        case "fullImage":
          el = <FullImage key={c._key} {...c} />;
          break;
        case "lottie":
          el = <LottieSection key={c._key} {...c} />;
          break;
        case "rive":
          el = (
            <RiveTier
              key={c._key}
              riveSource={c.riveFile}
              riveStateMachineName={c.riveStateMachineName}
              cursorHidden={c.hideMouseCursor}
              backgroundColor={c.backgroundColor}
              className={styles.riveTier}
            />
          );
          break;
        case "slider":
          el = (
            <Slider key={c._key} bottomMargin widthLimit isThemeDark={isThemeDark}>
              {c.slides.map((slide, index) => {
                return (
                  <ImageCard
                    image={slide.desktopImage}
                    mobileImage={slide.mobileImage}
                    title={slide.title}
                    key={index}
                    centered
                  />
                );
              })}
            </Slider>
          );

          break;
        default:
          el = null;
      }
      return el;
    });
  return (
    <div
      className={cn(
        styles.root,
        isThemeDark && styles.dark,
        hasPaddingTop && styles.hasPaddingTop,
        className
      )}
    >
      {sections}
    </div>
  );
};

export default PageSections;
