import React from "react";
import { cn } from "../lib/helpers";

import { bodyTextBlock } from "./typography.module.css";
import PortableText from "./portableText";
import ImageCard from "../components/image-card";
import Video from "../components/video";
import LottieSection from "./lottie-section";

import styles from "./two-column.module.css";

const TwoColumn = (props) => {
  const { columns } = props;
  return (
    <div className={styles.root}>
      <div className={styles.columnsWrapper}>
        {columns &&
          columns.map((column, index) => {
            if (column._type == "captionImage") {
              return (
                <div className={styles.imageColumn} key={index}>
                  <ImageCard
                    image={column}
                    mobileImage={column}
                    title={column.caption}
                    centered
                    full
                  />
                </div>
              );
            }
            if (column._type == "textColumn") {
              return (
                <div
                  className={cn(
                    styles.textColumn,
                    bodyTextBlock,
                    column.verticalCenter && styles.verticalCenter
                  )}
                  key={index}
                >
                  <PortableText blocks={column.textBlock} />
                </div>
              );
            }
            if (column._type == "video") {
              return (
                <div className={cn(styles.videoColumn, styles.verticalCenter)} key={index}>
                  <Video {...column} disablePadding />
                </div>
              );
            }
            if (column._type == "lottie") {
              return (
                <div className={cn(styles.lottieColumn, styles.verticalCenter)} key={index}>
                  <LottieSection {...column} />
                </div>
              );
            }
          })}
      </div>
    </div>
  );
};

export default TwoColumn;
