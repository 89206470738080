import React, { useEffect } from "react";
import { cn } from "../lib/helpers";
import { useRive, EventType, RiveEventType } from "@rive-app/react-canvas";
import { responsiveMicro } from "./typography.module.css";
import styles from "./rive-tier.module.css";

const RiveTier = ({
  riveSource,
  riveStateMachineName,
  className,
  cursorHidden = false,
  backgroundColor,
}) => {
  const { rive, RiveComponent } = useRive({
    src: riveSource.asset.url,
    stateMachines: riveStateMachineName,
    autoplay: true,
    isTouchScrollEnabled: true,
  });

  const onRiveEventReceived = (riveEvent) => {
    const eventData = riveEvent.data;
    if (eventData.type === RiveEventType.OpenUrl) {
      // Handle OpenUrl event manually
      window.location.href = eventData.url;
    }
  };

  // Wait until the rive object is instantiated before adding the Rive
  // event listener
  useEffect(() => {
    if (rive) {
      rive.resizeDrawingSurfaceToCanvas();
      rive.on(EventType.RiveEvent, onRiveEventReceived);
    }
  }, [rive]);

  return (
    <div
      className={cn(responsiveMicro, styles.root, className, cursorHidden && styles.cursorHidden)}
      {...(backgroundColor ? { style: { backgroundColor: backgroundColor } } : {})}
    >
      <div className={styles.riveContainer}>
        <RiveComponent />
      </div>
    </div>
  );
};

export default RiveTier;
