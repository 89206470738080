import React from "react";
import styles from "./text-section.module.css";
import { cn } from "../lib/helpers";

import { bodyTextBlock } from "./typography.module.css";
import PortableText from "./portableText";

const TextSection = (props) => {
  const { bodyText, alignment, disablePadding } = props;
  const alignmentStyle =
    alignment == "left"
      ? styles.alignLeft
      : alignment == "right"
      ? styles.alignRight
      : styles.alignCenter;
  return (
    <div
      className={cn(
        styles.root,
        bodyTextBlock,
        alignmentStyle,
        disablePadding ? styles.disablePadding : ""
      )}
    >
      <div className={styles.textInner}>
        <PortableText blocks={bodyText} />
      </div>
    </div>
  );
};

export default TextSection;
