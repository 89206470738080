import React from "react";
import ImageCard from "./image-card";
import styles from "./full-image.module.css";

const FullImage = ({ images }) => {
  const { desktopImage, mobileImage, title } = images;
  return (
    <div className={styles.root}>
      <ImageCard image={desktopImage} mobileImage={mobileImage} title={title} centered />
    </div>
  );
};

export default FullImage;
