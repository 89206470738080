import React from "react";
import { useMediaQuery } from "react-responsive";

import { buildImageObj, cn } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { mediaMinSmall, mediaMaxSmall } from "../lib/mediaQueries";

import CardTitle from "./card-title";
import styles from "./image-card.module.css";

const ImageCard = (props) => {
  const { image, mobileImage, title, centered, full } = props;

  const isDesktop = useMediaQuery(mediaMinSmall);
  const isMobile = useMediaQuery(mediaMaxSmall);

  return (
    <div className={cn(styles.root, centered && styles.centered, full && styles.full)}>
      <div className={styles.inner}>
        {isMobile && mobileImage && (
          <img
            src={imageUrlFor(buildImageObj(mobileImage)).auto("format").url()}
            alt={mobileImage.alt}
          />
        )}
        {(isDesktop || !mobileImage) && (
          <img
            src={imageUrlFor(buildImageObj(image)).auto("format").quality(100).url()}
            alt={image.alt}
          />
        )}
        {title && <CardTitle title={title} />}
      </div>
    </div>
  );
};

export default ImageCard;
