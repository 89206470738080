import React, { useRef } from "react";
import { buildImageObj, cn } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import styles from "./video.module.css";
import CardTitle from "./card-title";

import ReactPlayer from "react-player";
// test id: 143418951

const Video = (props) => {
  const {
    videoId,
    videoType,
    title,
    disableControls,
    aspectRatio,
    useCustomAspectRatio,
    customAspectRatioHeight,
    customAspectRatioWidth,
    disablePadding,
    image,
  } = props;

  const playerRef = useRef(null);

  const sourceUrls = {
    youtube: "https://www.youtube.com/watch?v=",
    vimeo: "https://vimeo.com/",
  };

  const defaultOptions = {
    controls: true,
    playing: true,
    light: image ? imageUrlFor(buildImageObj(image)).auto("format").quality(100).url() : true,
    onEnded: () => {
      playerRef.current.showPreview();
    },
  };
  const disabledControlsOptions = {
    muted: true,
    playing: true,
    controls: false,
    light: false,
    loop: true,
  };
  const customAspectRatioStyle = {
    aspectRatio: `${customAspectRatioWidth || "16"}/${customAspectRatioHeight || "9"}`,
  };
  return (
    <div className={styles.root}>
      <div className={cn(styles.videoInner, disablePadding ? styles.disablePadding : "")}>
        <div
          className={cn(
            styles.playerWrapper,
            !useCustomAspectRatio
              ? aspectRatio
                ? styles[aspectRatio]
                : styles.sixteenByNine
              : null
          )}
          style={useCustomAspectRatio ? customAspectRatioStyle : null}
        >
          <ReactPlayer
            url={sourceUrls[videoType] + videoId}
            className={styles.player}
            width="100%"
            height="100%"
            ref={playerRef}
            {...(disableControls === true ? disabledControlsOptions : defaultOptions)}
          />
        </div>
        {title && <CardTitle title={title} />}
      </div>
    </div>
  );
};

export default Video;
