import React from "react";
import shieldBlank from "../images/shield-blank.svg";
import { cn } from "../lib/helpers";

import { responsiveMicro } from "./typography.module.css";
import styles from "./card-title.module.css";

const CardTitle = (props) => {
  const { title } = props;
  return (
    <div className={cn(responsiveMicro, styles.root)}>
      <img className={styles.shieldBlank} src={shieldBlank} alt="Shield" />
      <span>{title}</span>
    </div>
  );
};

export default CardTitle;
